import Notify from 'quasar/src/plugins/Notify.js';;

let frontVersion = "";

export default class ValidarVersionService{
  async validarVersionFront(){
    await this.getVersion();

    let frontVersionStorage = localStorage.getItem('frontVersion');
    
    console.log(frontVersion);
    
    if(frontVersion !== "" && frontVersion != frontVersionStorage){
      this.mostrarAlert('Proyectos', 'Existe una nueva versión disponible', '', 'bottom-left', 'info');
    }
  }

  getVersion() {
    let isProduction = process.env.NODE_ENV === "production";
    let pathBase = !isProduction ? process.env.VUE_APP_BASE_URL : '/';
    
    return new Promise(resolve => {
      if (!isProduction) {
        fetch(pathBase + 'properties.json')
          .then(response => response.json())
          .then(data => {
            
            frontVersion = data.FRONT_VERSION;
          }).catch(err => console.log(err));
      } else {
        frontVersion = process.env.VUE_APP_FRONT_VERSION;
      }
      resolve();
    });
  }

  mostrarAlert (mensaje, caption, tipo = "", position = "top", icon){
    let conf = {
      message: mensaje, 
      caption: caption,
      position,
      timeout: 1000 * 20,
      html: true,
      icon:icon,
      classes: "text-black",
      actions: [
        { class: 'text-primary', label: 'Actualizar', handler: () => {
          localStorage.setItem('frontVersion',frontVersion);
          location.reload();
        } },
        { class: 'text-primary', label: 'Rercordar más tarde', handler: () => { /* ... */ } }
      ]
    };
  
    switch (tipo) {
      case "exito":
        conf.color = "green-8";
        break;
      case "error":
        conf.color = "red-8";
        break;
      case "warning":
        conf.color = "ambar-8";
        break;
      default:
        conf.color = "white";
    }
  
    Notify.create(conf);
  }
}