<template>
  <q-layout view="lhh lpR lfr" class="min-template">
    <q-header class="bg-white text-grey-system border-bottom">
      <q-toolbar>
        <div class="w100p h30 row items-center">
          <div class="col-9">
            <q-btn unelevated text-color="grey-system" :color="menuOperacion ? 'b-menu-active' : 'white'"
                   label="Operación" rounded @click="cargarMenu(2)" class="q-mr-lg"/>
            <q-btn unelevated text-color="grey-system" rounded label="Administración" class="q-mr-lg"/>
            <q-btn unelevated text-color="grey-system" rounded label="Dashboards"/>
          </div>
          <div class="col-3 row justify-end items-center">
            <span class="q-mr-md">Hola, <span class="fs-w-600">{{ usuarioNombreCompleto }} !</span></span>
            <q-btn-dropdown flat color="grey-8" class="dropdown-header">
              <template #label>
                <q-icon name="person" size="25px"/>
              </template>
              <div class="w270">
                <div class="row no-wrap bg-gris-panel">
                  <div class="col-2 border-right-bg14 overflow-auto h200 pd-l10">
                    <div
                        :class="{ deptoActive: departamentActivo === depto.departamento_id }"
                        v-for="(depto, index) in departamentos"
                        :key="index"
                        class="q-mt-xs q-mb-sm"
                    >
                      <q-btn
                          color="primary"
                          text-color="white"
                          :label="depto.nombre.substring(0, 1)"
                          round
                          dense
                          @click="seleccionarDepartamento(depto.departamento_id)"
                          unelevated
                      >
                        <q-tooltip>{{ depto.nombre }}</q-tooltip>
                      </q-btn>
                    </div>
                  </div>
                  <div class="col-10 q-mb-md">
                    <q-item class="q-mb-sm q-mt-xs" dense>
                      <q-item-section avatar class="q-pr-sm mw0">
                        <q-avatar rounded size="sm">
                          <img src="https://cdn.quasar.dev/img/boy-avatar.png"/>
                        </q-avatar>
                      </q-item-section>
                      <q-item-section v-text="usuario"/>
                    </q-item>
                    <q-list dense>
                      <q-item clickable>
                        <q-item-section>Ver perfil</q-item-section>
                        <q-item-section side>
                          <q-icon name="assignment_ind" size="16px"/>
                        </q-item-section>
                      </q-item>
                      <q-item clickable exact to="/configuraciones/home">
                        <q-item-section>Configuraciones</q-item-section>
                        <q-item-section side>
                          <q-icon name="mdi-tune" size="16px"/>
                        </q-item-section>
                      </q-item>
                      <q-item clickable :to="{name: 'perfilGestor'}">
                        <q-item-section>Perfiles de acceso</q-item-section>
                        <q-item-section side>
                          <q-icon name="recent_actors" size="16px"/>
                        </q-item-section>
                      </q-item>
                      <q-item clickable>
                        <q-item-section>Permisos</q-item-section>
                        <q-item-section side>
                          <q-icon name="mdi-account-lock-outline" size="16px"/>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </div>
                </div>
                <q-separator color="grey-6"/>
                <div class="bg-accent items-center">
                  <q-btn
                      color="white"
                      label="Cerrar session"
                      flat
                      v-close-popup
                      class="w100p"
                      @click="cerrarSession"
                  />
                </div>
              </div>
            </q-btn-dropdown>
          </div>
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer show-if-above side="left" :width="89" content-class="text-white bg-menu">
      <div class="text-center">
        <router-link to="/home" exact>
          <img :src="rutaLogo" style="height:60px" alt="logo"/>
        </router-link>
      </div>
      <q-separator inset color="white"/>
      <menu-operacion v-if=" menuOperacion"/>
    </q-drawer>

    <q-page-container>
      <router-view/>
    </q-page-container>
  </q-layout>
</template>

<script>
import ValidarVersionService from "../../Services/ValidarVersionService";

const validarVersionService = new ValidarVersionService();

export default {
  name: "Template",
  components: {
    menuOperacion: () => import("../menus/menuOperacion"),
  },
  data() {
    return {
      menuVentas: false,
      menuOperacion: false,
      menuInventario: false,
      departamentActivo: "",
      departamentos: [],
      usuario: localStorage.getItem("usuario"),
      rutaLogo: localStorage.getItem("amazonS3") + "assets/imagenes/logoisotipo.png",
      usuarioNombreCompleto: localStorage.getItem("nombreCompleto"),
    };
  },
  mounted() {
    let tipoMenu = localStorage.getItem("menu") === null ? 1 : localStorage.getItem("menu");
    this.cargarMenu(Number(tipoMenu));

    setInterval(async ()=>{
      await validarVersionService.validarVersionFront();
    }, 60000);
  },
  methods: {
    cargarMenu(tipoMenu) {
      switch (tipoMenu) {
        case 1:
          this.menuOperacion = true;
          localStorage.setItem("menu", "1");
          break;
      }
    },
    seleccionarDepartamento(departamentoId) {
      this.departamentActivo = departamentoId;
    },
    cerrarSession() {
      localStorage.clear();
      this.$router.push("/login");
    }
  }
};
</script>
